



















import Vue from 'vue';
// @ts-ignore
import LazyHydrate from 'vue-lazy-hydration';
import { PortalContainers } from '@devhacker/shared/plugins/portal';
import buildBuildInfo from '@devhacker/shared/features/BuildInfo/buildBuildInfo';
import type { AdfoxParams, AdfoxParamsWithIp } from '~/store/adfoxParams';

import DarkBackgroundContainer from '~/containers/DarkBackgroundContainer.vue';
import TheBadges from '~/components/Pusher/TheBadges/TheBadges.vue';

import GoogleOneTapAuth from '~/components/GoogleOneTapAuth/GoogleOneTapAuth.vue';
import { ADD_TYPO_TEXT_AND_SHOW_FORM } from '~/constants';
import { lowZindexTopHeaderContainer, highZindexTopHeaderContainer, isRetina } from '~/utils';
import { pushBadge } from '~/components/Pusher/websockets-functions';

const SafariPushWindowContainer = () => import('~/containers/SafariPushWindowContainer/SafariPushWindowContainer.vue' /* webpackChunkName: 'SafariPushWindowContainer' */);

const LoginWindow = () => import('~/components/LoginWindow/LoginWindow.vue' /* webpackChunkName: 'LoginWindow' */);

const TypoFormContainer = () => import('~/containers/TypoFormContainer' /* webpackChunkName: 'TypoFormContainer' */);

export default Vue.extend({
  name: 'ProfileLayout',
  components: {
    LazyHydrate,
    DarkBackgroundContainer,
    GoogleOneTapAuth,
    PortalContainers,
    TheBadges,
    LoginWindow,
    SafariPushWindowContainer,
    TypoFormContainer,
  },

  props: {
    typoFormEnabled: {
      type: Boolean,
      default: false,
    },

    syncAdfoxParams: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    adfoxParams (): AdfoxParamsWithIp {
      return this.$store.getters.currentAdfox;
    },
    isTypoFormVisible (): boolean {
      return this.typoFormEnabled && this.$store.state.typos.isShowForm;
    },
  },

  watch: {
    isTypoFormVisible (isShow: boolean): void {
      if (isShow) {
        lowZindexTopHeaderContainer();
      } else {
        highZindexTopHeaderContainer();
      }
    },

    adfoxParams (val: AdfoxParams): void {
      if (this.syncAdfoxParams) {
        this.writeAdfoxParamsToWindow(val);
      }
    },
  },

  mounted () {
    // выводим версию билда в консоль
    Object.assign(window, {
      buildInfo: buildBuildInfo(),
    });

    if (this.syncAdfoxParams) {
      this.writeAdfoxParamsToWindow(this.adfoxParams);
    }

    if (this.typoFormEnabled) {
      this.onKeyDown = this.onKeyDown.bind(this);
      window.addEventListener('keydown', this.onKeyDown);
    }
  },

  destroyed () {
    if (this.typoFormEnabled) {
      window.removeEventListener('keydown', this.onKeyDown);
    }
  },

  methods: {
    onKeyDown (event: KeyboardEvent): void {
      //  Обрабатываем только Ctrl + Enter и только если форма не открыта
      if (!event.ctrlKey || event.code !== 'Enter' || this.isTypoFormVisible) {
        return;
      }
      const isSearchVisible = this.$store.state.visibleModalWindows.includes('search');
      if (isSearchVisible) {
        return;
      }

      const selection = window.getSelection();
      const text = selection?.toString();

      if (!text) {
        return;
      }

      if (text.length > 300) {
        pushBadge(this.$store.dispatch, {
          content: 'Пожалуйста, выделите конкретное слово или предложение с ошибкой.',
          isError: true,
        });
        return;
      }

      let context = selection?.getRangeAt(0)?.startContainer?.parentNode?.textContent ?? '';
      context = context.replace(text, `<b>${text}</b>`);

      this.$store.commit(ADD_TYPO_TEXT_AND_SHOW_FORM, { text, context });
    },

    writeAdfoxParamsToWindow (val: AdfoxParams): void {
      Object.assign(
        window,
        {
          ...val,
          lh_adfox_rtn: isRetina(),
        },
      );
    },
  },
});
